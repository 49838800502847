import React from 'react';
import {Icon, useQueryParams} from 'shared/helpers';
import {CategoriesAndItems, Search} from 'shared';
import {Product, Breadcrumbs} from 'components/customer/Product';

import styled from 'styled-components';
import {Header} from '../RoomPlanner/components/Menu/Header';

export interface ProductsProps {
    showTitle?: boolean;
    showBreadcrumbs?: boolean;
    isMiniBrowser?: boolean;
}

export const Products = ({
    showTitle,
    showBreadcrumbs,
    isMiniBrowser = false,
}: ProductsProps) => {
    const {product, cabinetId} = useQueryParams() as {
        product: string;
        cabinetId: string;
    };
    const isProductPage = !!product;

    return (
        <ProductsContainer
            $isMiniBrowser={isMiniBrowser}
            $isProduct={!!product}
            className={
                !product ? 'Products product' : 'Products product bottomMargin'
            }>
            {showTitle ? (
                <h1>
                    <Icon iconName="Headers-Add-Product.svg" />
                    <label>{cabinetId ? 'Edit' : 'Add'} Product</label>
                </h1>
            ) : null}

            <ProductContent
                className="product-content"
                $isMiniBrowser={isMiniBrowser}
                $hasProduct={isProductPage}>
                {isMiniBrowser ? (
                    <Header showBreadcrumbs={showBreadcrumbs} />
                ) : (
                    <div className="search-info">
                        <Breadcrumbs />
                    </div>
                )}
                <div>
                    {!product && !isMiniBrowser ? (
                        <div className="productSearch">
                            <label style={showTitle ? {} : {fontSize: '0.8em'}}>
                                Product Search:
                            </label>

                            <div className="productSearchField">
                                <Search isMiniBrowser={isMiniBrowser} />
                            </div>
                        </div>
                    ) : null}

                    <div className="productsAndCategories">
                        {!product ? (
                            <CategoriesAndItems isProduct={true} />
                        ) : (
                            <Product />
                        )}
                    </div>
                </div>
            </ProductContent>
        </ProductsContainer>
    );
};

const ProductsContainer = styled.div<{
    $isMiniBrowser: boolean;
    $isProduct: boolean;
}>`
    ${({$isMiniBrowser, $isProduct}) => {
        if ($isMiniBrowser && $isProduct) {
            return 'margin-bottom: 105px !important;';
        }
    }}
`;

const ProductContent = styled.div<{
    $hasProduct?: boolean;
    $isMiniBrowser: boolean;
}>`
    ${({$hasProduct, $isMiniBrowser}) => {
        return `margin-top: ${
            !$isMiniBrowser ? '0' : $hasProduct ? '40px' : '50px'
        };`;
    }}
`;
